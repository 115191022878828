.progress-container {
    transition: width 0.2s ease-in-out;
    width: 100%;
    height: 4px;
    background: #f3f3f3;
  
    @media (min-width: 768px) {
      margin-left: 1.7%;
      width: 96%;
      margin-top: -4px;
      position: absolute;
    }
  }
  
  .progress-bar-header {
    height: 4px;
    border-radius: 100px;
    background: #EC6525;
    z-index: 1000;
  }
  