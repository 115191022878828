//@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

// @media (max-width: 1440px) {

//   .Evbar {

//       &.home {
//         top: 744px !important;
//         left: 105px!important;
//       }

//   }

//   }

// @media screen and (min-width: 1024px) and (max-width: 1440px) {

//   .Evbar.home {

//     top: 626px!important;
//   }

// }



.Evbar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  @media (min-width: 1044px) {
    // top: 15px;
    // left: 30px;
    // width: calc(100% - 60px);
    top: 14px;
    left: 169px;

    width: calc(86% - 60px);

    &.home {
      top: 87vh;
      left: 169px;
    }

    .navbar {
      border-radius: 7.5px;
    }
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;
  background-color: #fbfbfd;
  box-shadow: 0 2px 8px rgba(100, 100, 100, 0.18);
  border: 1px solid #cccccc22;


  a {
    text-decoration: none;
    color: #1e1e1e !important;

    font-family: Plus Jakarta Sans, sans-serif;

    font-weight: 800 !important;
  }

  a:hover {
    text-decoration: underline;
  }

  button {
    color: #1e1e1e !important;
    border: none;
    background-color: transparent;
    font-weight: 800 !important;
    font-family: Plus Jakarta Sans, sans-serif;
  }

  button:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .dropdown {
    position: relative;

    ul {
      list-style: none;
      position: absolute;
      right: -100%;
      background-color: #fbfbfd;
      box-shadow: 0 2px 8px rgba(100, 100, 100, 0.18);
      border-radius: 7.5px;
      padding: 0px 15px 10px 15px;
      width: fit-content !important;
      height: fit-content;
      
      
      @media (max-width: 1044px) {
        width: fit-content;
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: 1044px) {
        left: 50%;
        transform: translateX(-50%);
      }

      li {
        margin-top: 10px;
        text-wrap: nowrap;
      }
    }

    .up-direction {
      bottom: 50px;
    }

    .down-direction {
      top: 50px;
    }
  }


  &__logo {
    font-size: 24px;
    font-weight: bold;
    height: 35px;

    cursor: pointer;
  }

  &__icon {
    font-size: 24px;
    cursor: pointer;

    @media (min-width: 1044px) {
      display: none;
    }
  }

  &__links {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    height: calc(100vh - 60px);
    transform: translateX(100%);
    justify-content: space-around;
    transition: transform 0.3s ease-in-out;


    @media (max-width: 1044px) {
      width: fit-content !important;
      height: auto;
    }

    &.navbar__showMenu {
      transform: translateX(0);

      border-radius: 15px;

    }

    @media (max-width: 1044px) {
      &.navbar__showMenu {
        max-height: 650px;
        box-shadow: 0px 0px 100px #777777;
      }

    }

    @media (min-width: 1044px) {
      flex-direction: row;
      position: initial;
      transform: translateX(0);
      height: auto;

      background-color: transparent;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      margin-left: auto;
      margin-top: 15px;

    }
  }

  .item__menu {

    @media (min-width: 1024px) {
      margin-bottom: -76px;
      margin-left: 55px;

    }
  }

  &__item {
    width: 90%;
    padding: 20px;
    border-bottom: 1px solid #dddddd;

    &--hasSubMenu {
      .navbar__subMenu {
        list-style: none;
        padding-left: 20px;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s ease-in-out;

        &.navbar__showSubMenu {
          transform: scaleY(1);
        }
      }

      .navbar__subItem {
        padding: 10px 0;
        border-bottom: 1px solid #dddddd;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    @media (min-width: 1044px) {
      width: auto;
      padding: 0;
      margin-left: 20px;
      border-bottom: none;

      &:first-child {
        margin-left: 0;
      }

    }
  }
}


@media (max-width: 1044px) {
  .navbar__item {
    font-size: 15px !important;
    text-align: left !important;

    a {
      font-size: 15px !important;
    }
  }

}